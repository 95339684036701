import React, { useEffect, useState } from "react";
import "../../assets/styles/notification.css";
import "../../Layout/Header/Header.css";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants/apiroutes";
import { commonService } from "../../services/common.service";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { Enums } from "../../constants";
import NewLoader from "../../components/loader/NewLoader";

export default function Notifications() {  
  const userType = JSON.parse(
    commonService.getItem("user")
  ).association_type_term;
  const [notifications, setNotifications] = useState(null);
  const [totalotification, setTotalNotification] = useState(null);
  const [page, setPage] = useState(1);
  const [hashMore, setHashMore] = useState(true);
  const navigate = useNavigate();

  const hideViewNotificationArray = [
    "ticket_request_accepted",
    "ticket_request_rejected",
    "ticket_request_completed",
  ];

  const fetchNotificatons = async () => {
    let data = {
      page: page,
    };
    let res = await APICall(ApiRoutes.allNotifications, data);
    if (res.status === 1) {
      setTotalNotification(res.data.total);
      if (notifications) {
        if (res.data.total == res.data.list.length + notifications.length) {
          setHashMore(false);
        }

        setNotifications([...notifications, ...res.data.list]);
      } else {
        if (res.data.total == res.data.list.length) {
          setHashMore(false);
        }

        setNotifications(res?.data?.list);
      }
    }
  };
  const markAsRead = async () => {
    let res = await APICall(ApiRoutes.markAsReadNotifications, { read_all: 1 });
    if (res.status === 1) {
      // setNotifications(res?.data?.list);
    }
  };

  const redirectToNotyPage = (notificationRecord) => {
    let notificationData = JSON.parse(notificationRecord.data);
    let notification_type = notificationRecord.notification_type;
    if (notification_type == "client_registered") {
      if (userType === Enums.UserRole.admin) {
        if ("client_id" in notificationData) {
          navigate(`/clients/view/${notificationData.client_id}`, {
            replace: true,
          });
        }
      }
    }
    if (
      notification_type == "quotation_confirmed_by_client" ||
      notification_type == "new_quotation_received"
    ) {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("quotation_id" in notificationData) {
          navigate("/quotationreq/view", {
            replace: true,
            state: {
              id: notificationData.quotation_id,
            },
          });
        }
      }
    }
    if (notification_type == "quotation_accepted_by_company") {
      if (userType === Enums.UserRole.client) {
        if ("quotation_id" in notificationData) {
          navigate("/myquotation/view", {
            replace: true,
            state: {
              id: notificationData.quotation_id,
            },
          });
        }
      }
    }

    if (
      notification_type == "contract_created" ||
      notification_type == "contract_invoice_send" ||
      notification_type == "received_rating_title" ||
      notification_type == "association_contract_expiry"
    ) {
      if (userType === Enums.UserRole.client) {
        if ("contract_id" in notificationData) {
          navigate("/client_contracts/view", {
            replace: true,
            state: {
              id: notificationData.contract_id,
            },
          });
        }
      } else if (
        userType === Enums.UserRole.admin ||
        userType === Enums.UserRole.branch_manager ||
        userType === Enums.UserRole.company
      ) {
        if ("contract_id" in notificationData) {
          navigate("/contracts/view", {
            replace: true,
            state: {
              id: notificationData.contract_id,
            },
          });
        }
      }
    }
    if (notification_type == "quotation_rejected_by_company") {
      if (userType === Enums.UserRole.client) {
        if ("quotation_id" in notificationData) {
          navigate("/myquotation/view", {
            replace: true,
            state: {
              id: notificationData.quotation_id,
            },
          });
        }
      }
    }
    if (notification_type == "contract_expiry") {
      if ("contract_id" in notificationData) {
        navigate("/contracts/view", {
          replace: true,
          state: {
            id: notificationData.contract_id,
          },
        });
      }
    }
    if (
      notification_type == "guard_signup" ||
      notification_type == "guard_approval_status" ||
      notification_type == "guard_active_status"
    ) {
      if (
        userType === Enums.UserRole.admin ||
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("guard_id" in notificationData) {
          navigate(`/guard/view/${notificationData.guard_id}`);
        }
      }
    }
    if (
      notification_type == "incharge_signup" ||
      notification_type == "incharge_approval_status"
    ) {
      if (
        userType === Enums.UserRole.admin ||
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("incharge_id" in notificationData) {
          navigate(`/incharge/view/${notificationData.incharge_id}`);
        }
      }
    }
    if (notification_type == "association_service_expiry") {
      if (userType === Enums.UserRole.admin) {
        if ("company_id" in notificationData) {
          navigate(`/company/view/${notificationData.company_id}`);
        }
      }
    }
    if (notification_type == "incharge_status_change") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("incharge_id" in notificationData) {
          navigate(`/incharge/view/${notificationData.incharge_id}`);
        }
      }
    }
    if (notification_type == "branch_added") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.admin
      ) {
        if ("branch_id" in notificationData) {
          navigate(`/branch/view/${notificationData.branch_id}`);
        }
      }
    }
    if (notification_type == "branch_approval_status") {
      if (userType === Enums.UserRole.company) {
        if ("branch_id" in notificationData) {
          navigate(`/branch/view/${notificationData.branch_id}`);
        }
      }
    }
    if (notification_type == "incident_added") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("incident_id" in notificationData) {
          navigate(`/incidents/view/${notificationData.incident_id}`);
        }
      }
    }
    if (notification_type == "leave_request_applied") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("leave_id" in notificationData) {
          navigate(`/leaves/view/${notificationData.leave_id}`);
        }
      }
    }
    if (notification_type == "received_advance_salary_req") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("payment_request_id" in notificationData) {
          navigate(
            `/payment_requests/view/${notificationData.payment_request_id}`
          );
        }
      }
    }
    if (notification_type == "complain_received") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("ticket_request_id" in notificationData) {
          navigate(`/complaint/view/${notificationData.ticket_request_id}`);
        }
      }
    }
    if (notification_type == "incident_added") {
      if (
        userType === Enums.UserRole.company ||
        userType === Enums.UserRole.branch_manager
      ) {
        if ("incident_id" in notificationData) {
          navigate(`/incidents/view/${notificationData.incident_id}`);
        }
      }
    }
    // if(notification_type == 'association_service_expiry'){
    //     if (userType === Enums.UserRole.admin){

    //     }
    // }
  };

  useEffect(() => {
    fetchNotificatons();
  }, [page]);
  useEffect(() => {
    markAsRead();
  }, []);

  return (
    <div>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Notifications
            </h2>
          </div>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body">
          {notifications != undefined && notifications != null ? (
            notifications.length != 0 ? (
              <InfiniteScroll
                dataLength={notifications.length}
                next={() => {
                  setPage(page + 1);
                }}
                hasMore={hashMore}
                loader={
                  <div className="justify-content-center d-flex">
                    <Loader height={90} width={90} />
                  </div>
                }
              >
                {notifications.map((notification, index) => {
                  return (
                    <div key={index} className="noti-div-inner">
                      <div className="d-flex justify-content-between mt-2">
                        <div className="noti-dis">
                          {notification.notification_description}
                        </div>
                        {!hideViewNotificationArray.includes(
                          notification.notification_type
                        )
                          ? !(
                              userType !== Enums.UserRole.admin &&
                              notification.notification_type ===
                                "association_service_expiry"
                            )
                            ? notification.notification_type !==
                                "sos_request_alert" &&
                              notification.notification_type !==
                                "association_service_expiry" && (
                                <div>
                                  <span
                                    onClick={() =>
                                      redirectToNotyPage(notification)
                                    }
                                    className="cursor-pointer btn-sm btn-primary view-btn-noti"
                                  >
                                    View
                                  </span>
                                </div>
                              )
                            : ""
                          : ""}
                      </div>

                      <div className="noti-date">
                        {" "}
                        {commonService.convertUTCToLocal(
                          notification.created_at
                        )}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <div className="noti-not-found">No notifications found</div>
            )
          ) : (
            // <div className="notification-loader">
            //     {Array.apply(null, { length: 10 }).map((e, i) => (
            //         <div className='my-3 '>
            //             <div className="noti-fancy-loader my-2"></div>
            //             <div style={{ height: "14px", width: "20%" }} className="noti-fancy-loader my-2"></div>
            //         </div>
            //     ))}
            // </div>
            <NewLoader />
          )}
        </div>
      </div>
    </div>
  );
}

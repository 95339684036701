import React, { useState } from 'react'
import { Button, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';

export default function AddInchargeModal({ getDetails ,  contractId ,  selectedIncahrges ,   setSelectedIncharges , incharges , showAddIncharge , setShowAddIncharge  }) {

    const [inchargeError, setInchargeError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const closeAddInchargePopUp = () => {
        setShowAddIncharge(false);
        setSelectedIncharges([]);
        setInchargeError("");
    }
    const handleAddInchargeToContract = async () => {
        if (selectedIncahrges.length) {
            setIsLoading(true);
            let inchargeIds = []
            selectedIncahrges.forEach((selectedIncahrge) => {
                inchargeIds.push(selectedIncahrge.value);
            })
            let data = {
                contract_id: contractId,
                incharge_list: inchargeIds
            };
            let res = await APICall(ApiRoutes.addStaffMember, data)
            if (res.status == 1) {
                setIsLoading(false);
                closeAddInchargePopUp();
                getDetails();
            }
        } else {
            setInchargeError("Atleast one incharge should be selected.")
        }
    }
    return (
        <Modal className="modal-popup" show={showAddIncharge} onHide={closeAddInchargePopUp}>
        <Modal.Header closeButton>
          <Modal.Title>Select Incharge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropDown
            style={{ div: "col-md-12 col-12" }}
            data={incharges}
            title={"Incharge"}
            value={incharges.value}
            isMulti={true}
            onChange={(value) => setSelectedIncharges(value)}
            required
            validationText={inchargeError}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeAddInchargePopUp}>
            Close
          </Button>
          {isLoading === false ? (
            <>
              <Button variant="primary"
                onClick={handleAddInchargeToContract}
              >
                Save
              </Button>
            </>
          ) : (
            <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
}
